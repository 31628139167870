import React, { useState, useEffect, createContext, useCallback } from 'react'
import deepEqual from 'deep-equal'
import qualitative from '../../utils/colors/chartPalette'

const ColorContext = createContext(null)

const getNextColor = (idx) => qualitative[idx % qualitative.length]
const getColorMapArray = (series, colorMap, groupName) =>
    series.map((seriesName, idx) => ({
        series: seriesName,
        color: getNextColor(
            colorMap[groupName] ? colorMap[groupName].length + idx : idx
        ),
    }))

const ColorContextProvider = ({ children }) => {
    const [colorMap, setColorMap] = useState(null)
    const getAssociatedColor = useCallback(
        (seriesName, groupKey) => {
            if (seriesName && groupKey && colorMap && colorMap[groupKey]) {
                const matchingSeries = colorMap[groupKey].find(x => x.series === seriesName)
                if (matchingSeries) {
                    return matchingSeries ? matchingSeries.color : null
                }
            } else {
                if (colorMap && colorMap[null]) {
                    const nullSeries = colorMap[null].find(x => x.series === seriesName)
                    return nullSeries ? nullSeries.color : null
                }
            }
        },
        [colorMap]
    )

    const updateColorMap = useCallback(
        (groupName, groupSeries) => {
            if (colorMap) {
                let updatedColorMap = colorMap
                // find the associated color map that is tied to the groupName we have
                const associatedColorMap =
                    colorMap && colorMap[groupName] ? colorMap[groupName] : null
                if (associatedColorMap) {
                    const associatedSeries = associatedColorMap.map(
                        (x) => x.series
                    )
                    const seriesWithoutColorMaps = groupSeries.filter(
                        (x) => !associatedSeries.includes(x)
                    )
                    updatedColorMap = {
                        ...colorMap,
                        [groupName]: [
                            ...colorMap[groupName],
                            ...getColorMapArray(
                                seriesWithoutColorMaps,
                                colorMap,
                                groupName
                            ),
                        ],
                    }
                } else {
                    updatedColorMap = {
                        ...colorMap,
                        [groupName]: getColorMapArray(
                            groupSeries,
                            colorMap,
                            groupName
                        ),
                    }
                }
                if (!deepEqual(updatedColorMap, colorMap)) {
                    setColorMap(updatedColorMap)
                }
            } else {
                const updatedColorMap = {
                    [groupName]: getColorMapArray(groupSeries, {}, groupName),
                }
                setColorMap(updatedColorMap)
            }
        },
        [colorMap, setColorMap]
    )

    return (
        <ColorContext.Provider
            value={{ colorMap, updateColorMap, getAssociatedColor }}
        >
            {children}
        </ColorContext.Provider>
    )
}

export { ColorContext }
export default ColorContextProvider
